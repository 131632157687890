
import { Vue, Component } from 'vue-property-decorator';
import { addServiceKey, getServiceKey } from '@/api/serviceKey';
import { getLastOpenApiHelp } from '@/api/openApi';

@Component({
  name: 'EventApi',
})
export default class extends Vue {
  mounted() {
    this.getServiceKey();
    this.getLastHelpHistory();
  }

  private serviceKey = '';

  private lastHelpHistoryDate = '';

  private getServiceKey() {
    getServiceKey().then((res) => {
      this.serviceKey = res.data.uid;
    });
  }

  private handleClickServiceKey() {
    addServiceKey().then(() => {
      this.getServiceKey();
    });
  }

  private getLastHelpHistory() {
    getLastOpenApiHelp().then((res) => {
      if (res.data) this.lastHelpHistoryDate = res.data.createDate;
    });
  }

  private handleClickApply() {
    const popupForm = window.open('/open-api-form', 'CLIENT_WINDOW', 'resizable=yes scrollbars=yes width=450 height=450 left=0 top=0');
    const loop = setInterval(() => {
      if (popupForm && popupForm.closed) {
        clearInterval(loop);
        this.getLastHelpHistory();
      }
    }, 400);
  }
}
